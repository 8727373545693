<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center" class="mb-2">
        <v-col cols="auto" class="pr-12">
          <h1>Bookings</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Bookings"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed v-on="on">
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Bookings</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed color="warning" v-on="on" class="ml-2">
                  <v-icon small>mdi-history</v-icon>
                </v-btn>
              </template>
              <span>View Past Bookings</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  class="ml-2"
                  @click="$refs.bookingDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Booking</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="orders"
        class="mt-4"
        no-data-text="No Upcoming Swimming Bookings found"
      >
        <template v-slot:item.id="{ item }">
          <router-link
            :to="{
              name: 'module-arnprior-orders-individual',
              params: { orderId: item.id },
            }"
            >#{{ item.order_number }}</router-link
          >
        </template>
        <template v-slot:item.customer="{ item }">
          <router-link
            :to="{
              name: 'module-arnprior-customers-individual',
              params: { customerId: item.customer_id },
            }"
            >{{ item.customer.full_name }}</router-link
          >
        </template>
        <template v-slot:item.start_date_time.datetime="{ item }"
          >{{ item.start_date_time.date }}
          {{ item.start_date_time.time }}</template
        >
        <template v-slot:item.end_date_time.datetime="{ item }"
          >{{ item.end_date_time.date }} {{ item.end_date_time.time }}</template
        >
        <template v-slot:item.total="{ item }">£{{ item.total }}</template>
        <template v-slot:item.outstanding_total="{ item }">
          <span class="red--text" v-if="item.outstanding_total === item.total">
            £{{ item.outstanding_total }}
          </span>
          <span
            class="green--text"
            v-else-if="item.outstanding_total === '0.00'"
          >
            £{{ item.outstanding_total }}
          </span>
          <span class="orange--text" v-else>
            £{{ item.outstanding_total }}
          </span>
        </template>
        <template v-slot:item.has_swim_bookings="{ item }">
          <v-icon color="green" v-if="item.has_swim_bookings"
            >mdi-check-circle</v-icon
          >
        </template>
        <template v-slot:item.has_pool_party_bookings="{ item }">
          <v-icon color="green" v-if="item.has_pool_party_bookings"
            >mdi-check-circle</v-icon
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                v-on="on"
                :to="{
                  name: 'module-arnprior-orders-individual',
                  params: { orderId: item.id },
                }"
              >
                <v-icon x-small left>mdi-eye</v-icon> View
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <booking-dialog ref="bookingDialog" />
    <party-dialog ref="partyDialog" />
  </div>
</template>

<script>
import { arnprior } from "../../../mixins";
import BookingDialog from "./components/BookingDialog";
import PartyDialog from "../parties/components/BookingDialog";

export default {
  mixins: [arnprior],

  components: {
    BookingDialog,
    PartyDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Swimming Pool",
          disabled: true,
        },
        {
          text: "Bookings",
          disabled: true,
        },
      ],
      searchTerm: "",
      tableHeaders: [
        { text: "Order Number", value: "id" },
        { text: "Customer", value: "customer" },
        {
          text: "Start Date/Time",
          value: "start_date_time.datetime",
        },
        { text: "End Date/Time", value: "end_date_time.datetime" },
        { text: "Total", value: "total" },
        { text: "Outstanding Total", value: "outstanding_total" },
        { text: "Swim Booking", value: "has_swim_bookings" },
        { text: "Pool Party Booking", value: "has_pool_party_bookings" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    orders() {
      let orders = this.$store.getters["arnprior/ordersStore/all"];

      if (this.searchTerm !== "") {
        orders = orders.filter((o) => {
          const customerName = o.customer.full_name.toLowerCase();
          const customerEmail = o.customer.email.toLowerCase();
          const orderNumber = o.order_number.toLowerCase();
          const orderId = String(o.id);
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            customerName.includes(searchTerm) ||
            customerEmail.includes(searchTerm) ||
            orderNumber.includes(searchTerm) ||
            orderId.includes(searchTerm)
          );
        });
      }

      return orders;
    },
  },
};
</script>
